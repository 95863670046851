import React from 'react'
import Button from '../../components/common/Button'
import { navigate } from 'gatsby'
import SimpleHeader from '../../components/Layout/SimpleHeader'
import PrivateRoute from '../../components/PrivateRoute'
import { isWebview } from '../../utils/helpers'
import assessment from '../../images/quiz/assessment-checklist.svg'

const FollowUpComplete = () => {
  return (
    <>
      <SimpleHeader backButton={false} />
      <div className="bg-white">
        <div className="px-4 py-8 mx-auto max-w-7xl sm:py-24 sm:px-6 lg:px-8">
          <div className="space-y-12 text-center">
            <h1 className="text-4xl font-bold sofia text-gp-violet sm:text-5xl sm:tracking-tight">
              Thanks for taking your assessment!
            </h1>
            <div className="flex justify-center">
              <img
                src={assessment}
                className="w-32 fill-current text-gp-honey"
                alt="Completed assessment icon."
              />
            </div>
            <div className="flex justify-center">
              <Button
                onClick={() => {
                  if (isWebview && window?.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage(
                      JSON.stringify({ event: 'finished' })
                    )
                    return
                  }
                  return navigate('/')
                }}
              >
                {isWebview ? 'Finish assessment' : 'Return to Homepage'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const FollowUpCompleteWithPrivateRoute = ({ data, location }) => (
  <PrivateRoute location={location}>
    <FollowUpComplete path="/assessment/follow-up-complete" data={data} />
  </PrivateRoute>
)

export default FollowUpCompleteWithPrivateRoute
